import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import isEmpty from "../../../core/helpers/isEmpty";
import CustomTextHighlighter from "../customTextHighlighter";
const ReadMoreLess = ({ text, characterLimit }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleSetIsExpanded = (event) => {
        event.preventDefault();
        setIsExpanded(!isExpanded);
    };
    const renderText = useMemo(() => {
        if (!isEmpty(text) &&
            characterLimit &&
            (text === null || text === void 0 ? void 0 : text.length) > characterLimit &&
            !isExpanded) {
            return text.substring(0, characterLimit);
        }
        return text;
    }, [text, characterLimit, isExpanded]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomTextHighlighter, { text: renderText }),
        React.createElement(Link, { to: "", className: "more_or_less", onClick: handleSetIsExpanded, hidden: (text === null || text === void 0 ? void 0 : text.length) <= characterLimit }, isExpanded ? ` Less` : ` More`)));
};
export default ReadMoreLess;
