import VerticalLayout from "../layout/vertical/Vertical";
import LoginContainer from "../views/global/login/LoginContainer";
import PublicLayout from "../layout/publicLayout/publicLayout";
import RoleContainer from "../views/internal/admin/role/roleContainer";
import AddCompany from "../views/internal/admin/company/addCompany";
import EditCompany from "../views/internal/admin/company/editCompany";
import InviteUserContainer from "../views/internal//external/invite-user";
import AddSite from "../views/internal/admin/site/addSite";
import EditSite from "../views/internal/admin/site/editSite";
import { DASHBOARD_VIEWER, GROUP_VIEW, GROUP_CREATION, COMPANY_VIEW, COMPANY_CREATION, SITE_VIEW, SITE_CREATION, USER_CREATION, EXTERNAL_USER_CREATION, ROLE_VIEW, REPORT_CREATION, REPORT_VIEW, REPORT_DEFAULTS_EDIT, REPORT_DEFAULTS_VIEW, USER_REPORTS, AUDIT_LOGS_VIEWER, } from "../core/constants/permissionConstants";
import { ForgotPasswordContainer } from "../views/global/ForgotPassword/forgotPasswordContainer";
import ResetPasswordAfterFPassword from "../views/global/ResetPassword/resetPasswordAfterFPassword";
import UnAuthorized from "../views/global/sessions/401";
// import ApplicationSettingsContainer from "../views/internal/admin/settings";
import TrackedItemDefinitionFormContainer from "../views/internal/admin/manage-tracked-item/tracked-items-definition/tracked-items-form";
import LookupContainer from "../views/internal/admin/lookup-definition/lookupContainer";
import NotFound from "../views/global/sessions/404";
import taskListingContainer from "../views/internal/shared/task/taskListing/taskListingContainer";
import completeTask from "../views/internal/shared/task/complete-task";
import TrackedItemTypeFormContainer from "../views/internal/admin/manage-tracked-item/tracked-item-type/tracked-item-type-form";
import CompanyContainer from "../views/internal/admin/company/companyListing";
import GroupContainer from "../views/internal/admin/groups/groupListing";
import UserListingContainer from "../views/internal/admin/users/userListing/userListingContainer";
import UserFormContainer from "../views/internal/admin/users/userForm/userFormContainer";
import RegisterUserContainer from "../views/global/register-user/";
import SiteContainer from "../views/internal/admin/site/siteListing";
import MovingAverageAndProjectionChartContainer from "../views/internal/shared/charts/line-chart/movingAverageAndProjection";
import isEmpty from "../core/helpers/isEmpty";
import AddGroup from "../views/internal/admin/groups/addGroup";
import EditGroup from "../views/internal/admin/groups/editGroup";
import { USER_VIEW, LOOKUP_VIEW, REPORT_EDIT, REPORT_DEFAULTS_CREATION, } from "./../core/constants/permissionConstants";
import ManageTrackedItemTypesContainer from "../views/internal/admin/manage-tracked-item/tracked-item-type/tracked-item-type-listing/trackedItemTypeContainer";
import ManageTrackedItemContainer from "../views/internal/admin/manage-tracked-item/tracked-items-definition/tracked-items-listing/trackedItemContainer";
import NotificationsListingContainer from "../views/internal/admin/manage-notifications";
import InAppNotificationListContainer from "../views/internal/shared/inapp-notifications/notificationsList";
import ReportsContainer from "../views/internal/shared/reports";
import Home from "../views/internal/shared/home";
import MultiWatchListContainer from "../views/internal/admin/watchlist";
import RoadMapContainer from "../views/internal/shared/dashboard/DashboardRoadmap";
import CompanyDashboard from "../views/internal/shared/dashboard/CompanyDashboard";
import SubcategoriesDashboard from "../views/internal/shared/dashboard/SubcategoriesDashboard";
import SitesDashboard from "../views/internal/shared/dashboard/SitesDashboard";
import CategoriesDashboard from "../views/internal/shared/dashboard/CategoriesDashboard";
import TrackedItemsDashboard from "../views/internal/shared/dashboard/TrackedItemsDashboard";
import AuditLogsListingPage from "../views/internal/admin/audit-logs";
export const defaultRoutes = [
    {
        path: "groups",
        component: GroupContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [GROUP_CREATION],
    },
    {
        path: "watchlist",
        component: MultiWatchListContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "groups/add",
        component: AddGroup,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [GROUP_CREATION],
    },
    {
        path: "groups/edit/:id",
        component: EditGroup,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [GROUP_VIEW],
    },
    {
        path: "companies",
        component: CompanyContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [COMPANY_VIEW],
    },
    {
        path: "companies/add",
        component: AddCompany,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [COMPANY_CREATION],
    },
    {
        path: "companies/edit/:id",
        component: EditCompany,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [COMPANY_VIEW],
    },
    {
        path: "users",
        component: UserListingContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [USER_VIEW],
    },
    {
        path: "users/add",
        component: UserFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [USER_CREATION],
    },
    {
        path: "users/edit/:id",
        component: UserFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [USER_VIEW],
    },
    {
        path: "users/add-external-user",
        component: InviteUserContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [EXTERNAL_USER_CREATION],
    },
    {
        path: "users/register-user",
        component: RegisterUserContainer,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredPermissions: [],
    },
    {
        path: "sites",
        component: SiteContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [SITE_VIEW],
    },
    {
        path: "sites/add",
        component: AddSite,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [SITE_CREATION],
    },
    {
        path: "sites/edit/:id",
        component: EditSite,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [SITE_VIEW],
    },
    {
        path: "roles",
        component: RoleContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [ROLE_VIEW],
    },
    {
        path: "lookups",
        component: LookupContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [LOOKUP_VIEW],
    },
    // {
    //   path: "settings",
    //   component: ApplicationSettingsContainer,
    //   exact: true,
    //   layout: "vertical",
    //   layoutName: VerticalLayout,
    //   requiredPermissions: [GROUP_CREATION],
    // },
    {
        path: "manage-tracked-item-defaults",
        component: ManageTrackedItemTypesContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_DEFAULTS_VIEW],
    },
    {
        path: "manage-client-tracked-items",
        component: ManageTrackedItemContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_VIEW],
    },
    {
        path: "manage-client-tracked-items/add",
        component: TrackedItemDefinitionFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_CREATION],
    },
    {
        path: "manage-client-tracked-items/edit/:id",
        component: TrackedItemDefinitionFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_EDIT],
    },
    {
        path: "manage-tracked-item-defaults/add",
        component: TrackedItemTypeFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_DEFAULTS_CREATION],
    },
    {
        path: "manage-tracked-item-defaults/edit/:id",
        component: TrackedItemTypeFormContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [REPORT_DEFAULTS_EDIT],
    },
    {
        path: "tracked-item-chart/:id",
        component: MovingAverageAndProjectionChartContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER, REPORT_VIEW],
    },
    // {
    //   path: "dashboard/:id",
    //   component: MovingAverageAndProjectionChartContainer,
    //   exact: true,
    //   layout: "vertical",
    //   layoutName: VerticalLayout,
    //   requiredPermissions: [DASHBOARD_VIEWER],
    // },
    {
        path: "tasks",
        component: taskListingContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [],
    },
    {
        path: "tasks/historical-task",
        component: completeTask,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [],
    },
    {
        path: "notifications-events",
        component: InAppNotificationListContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [],
    },
    {
        path: "notifications",
        component: NotificationsListingContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [SITE_VIEW],
    },
    {
        path: "reports",
        component: ReportsContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [USER_REPORTS],
    },
    // {
    //   path: "dashboard",
    //   component: Dashboard,
    //   exact: true,
    //   layout: "vertical",
    //   layoutName: VerticalLayout,
    //   requiredPermissions: [DASHBOARD_VIEWER],
    // },
    {
        path: "dashboard",
        component: RoadMapContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id",
        component: CompanyDashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id/:comp_id",
        component: SitesDashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id/:comp_id/:site_id",
        component: CategoriesDashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id/:comp_id/:site_id/:category_id",
        component: SubcategoriesDashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id/:comp_id/:site_id/:category_id/:sub_cat_id",
        component: TrackedItemsDashboard,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "dashboard/:group_id/:comp_id/:site_id/:category_id/:sub_cat_id/:id",
        component: MovingAverageAndProjectionChartContainer,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [DASHBOARD_VIEWER],
    },
    {
        path: "audit-logs",
        component: AuditLogsListingPage,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [AUDIT_LOGS_VIEWER],
    },
    {
        path: "sign-in",
        component: LoginContainer,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredPermissions: [],
    },
    {
        path: "forget-password",
        component: ForgotPasswordContainer,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredPermissions: [],
    },
    {
        path: "reset-password",
        component: ResetPasswordAfterFPassword,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredPermissions: [],
    },
    {
        path: "unauthorized",
        component: UnAuthorized,
        exact: true,
        layout: "public",
        layoutName: VerticalLayout,
        requiredPermissions: [],
    },
    {
        path: "home",
        component: Home,
        exact: true,
        layout: "vertical",
        layoutName: VerticalLayout,
        requiredPermissions: [],
    },
    {
        path: "page-404",
        component: NotFound,
        exact: true,
        layout: "public",
        layoutName: PublicLayout,
        requiredPermissions: [],
    },
];
export const getRoutesByPermission = (permissions) => {
    if (isEmpty(permissions)) {
        return defaultRoutes.filter((x) => x.requiredPermissions.length === 0);
    }
    return defaultRoutes.filter((x) => permissions.some((elm) => x.requiredPermissions.includes(elm.permission)) || x.requiredPermissions.length === 0);
};
export const getRoutePathsByPermission = (permissions) => {
    return defaultRoutes
        .filter((x) => permissions.some((elm) => x.requiredPermissions.includes(elm.permission)) || x.requiredPermissions.length === 0)
        .map((x) => {
        return "/" + x.layout + "/" + x.path;
    });
};
