import { trackedItemReducer } from "./trackedItem/reducer";
import { lookupReducer } from "./lookup/reducer";
import { userReducer } from "./user/reducer";
import { siteReducer } from "./site/reducer";
import { combineReducers } from "redux";
import { settingsReducer } from "./settings/reducer";
import { contextReducer } from "./context/reducer";
import { rootViewReducer } from "./rootView/reducer";
import { groupReducer } from "./group/reducer";
import { companyReducer } from "./company/reducer";
import { roleReducer } from "./role/reducer";
import { permissionReducer } from "./permission/reducer";
import { initialState as initialStoreState } from "./initialState";
import { COMPANY_CHANGED, GROUP_CHANGED, USERS_CHANGED, USER_LOGGED_OUT, } from "./context/types";
import { pendingUserReducer } from "./pending-user/reducer";
import { trackedItemMenuReducer } from "./tracked-item-menu/reducer";
import { taskReducer } from "./task/reducer";
import { trackedItemTypesReducer } from "./tracked-item-type/reducer";
import { trackedItemsPerSiteReducer } from "./trackedItemsPerSite/reducer";
import { togglePrintReducer } from "./togglePrint/reducer";
import { bandReducer } from "./band/reducer";
import { groupOptionReducer } from "./groupOption/reducer";
import { clientNotificationsReducer } from "./notification/client/reducer";
import { globalNotificationReducer } from "./notification/global/reducer";
import { inAppNotificationsReducer } from "./notification/in-app/reducer";
import { unreadNotificationsCountReducer } from "./notification/unreadCount/reducer";
import { searchInputReducer } from "./listingSearchInput/reducer";
import { groupHierarchyReducer } from "./groupHierarchy/reducer";
import { auditLogsReducer } from "./auditLogs/reducer";
const appReducer = combineReducers({
    context: contextReducer,
    settings: settingsReducer,
    rootView: rootViewReducer,
    groups: groupReducer,
    companies: companyReducer,
    sites: siteReducer,
    roles: roleReducer,
    permissions: permissionReducer,
    users: userReducer,
    pendingUsers: pendingUserReducer,
    lookups: lookupReducer,
    trackedItems: trackedItemReducer,
    userCreatedtrackedItems: trackedItemReducer,
    systemCreatedtrackedItems: trackedItemReducer,
    trackedItemTypes: trackedItemTypesReducer,
    systemCreatedTrackedItemTypes: trackedItemTypesReducer,
    trackedItemMenu: trackedItemMenuReducer,
    tasks: taskReducer,
    trackedItemsPerSite: trackedItemsPerSiteReducer,
    clientNotifications: clientNotificationsReducer,
    inAppNotifications: inAppNotificationsReducer,
    togglePrint: togglePrintReducer,
    bands: bandReducer,
    groupOptions: groupOptionReducer,
    groupDataHierarchy: groupHierarchyReducer,
    globalNotifications: globalNotificationReducer,
    unreadNotificationCount: unreadNotificationsCountReducer,
    searchInput: searchInputReducer,
    auditLogs: auditLogsReducer,
});
const rootReducer = (state, action) => {
    if (action.type === USER_LOGGED_OUT) {
        state = initialStoreState;
    }
    if (action.type === USERS_CHANGED) {
        state = Object.assign(Object.assign({}, state), { users: [], pendingUsers: [] });
    }
    if (action.type === COMPANY_CHANGED) {
        state = Object.assign(Object.assign({}, state), { users: [], pendingUsers: [], sites: [], roles: [], tasks: [] });
    }
    if (action.type === GROUP_CHANGED) {
        state = Object.assign(Object.assign({}, state), { companies: [], users: [], pendingUsers: [], sites: [], roles: [], tasks: [] });
    }
    return appReducer(state, action);
};
export default rootReducer;
