import { AuditLogsApi } from "../../core/api/auditLogsApi";
import { GET_AUDIT_LOGS_SUCCESS } from "./types";
export const getAuditLogsSuccess = (data) => ({
    type: GET_AUDIT_LOGS_SUCCESS,
    payload: data,
});
export function getAuditLogs(filters) {
    return function (dispatch) {
        return AuditLogsApi.getAuditLogsData(filters)
            .then((response) => {
            var _a;
            if (((_a = response.auditLogs) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dispatch(getAuditLogsSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
