import { GET_AUDIT_LOGS_SUCCESS } from "./types";
const initialState = null;
export const auditLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUDIT_LOGS_SUCCESS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};
