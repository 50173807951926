import { Button, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import moment from "moment";
import { useHistory } from "react-router";
import { InAppNotificationApi } from "../../../../../core/api/inAppNotificationApi";
import { LookupNames } from "../../../../../core/constants/lookup";
import { timeFilterOptionType } from "../../../../../core/enums/InAppNotification";
import { InAppStatus } from "../../../../../core/enums/InAppNotifications";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import localize from "../../../../../core/utils/translation-files";
import Header from "../../../../../layout/components/common/header";
import { notifications } from "../../../../../layout/components/notifications";
import LookupDropDown from "../../../../../layout/components/select/LookupDropDown";
import { getLookups } from "../../../../../redux/lookup/actions";
import { flagNotification, readNotification, unflagNotification, unreadNotification, } from "../../../../../redux/notification/in-app/actions";
import NotificationModel from "../components/notificationModel";
import "../style.scss";
import InAppNotificationTable from "./components/inAppNotificationTable";
// import { disabledDate } from "../../../../../core/validations/dateTimeValidations";
import activeArrow from "../../../../../assets/img/activeArrow.png";
import arrowIcon from "../../../../../assets/img/arrowIcon.png";
import beforeArrow from "../../../../../assets/img/beforeArrow.png";
import descriptionInfo from "../../../../../assets/img/descriptionInfo.png";
import emailIcon from "../../../../../assets/img/emailIcon.png";
import feedbackNew from "../../../../../assets/img/feedbackNew.png";
import goToVision from "../../../../../assets/img/goToRodaBI.png";
import visionLogo from "../../../../../assets/img/whiteLogo.png";
import { NotificationApi } from "../../../../../core/api/notificationApi";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import ListingSearch from "../../../../../layout/components/listingSearch";
import { updateUnreadNotificationCount } from "../../../../../redux/notification/unreadCount/actions";
// const { RangePicker } = DatePicker;
const InAppNotificationListContainer = ({ getLookups, timeFilterOptions, flagNotification, unflagNotification, unreadNotification, readNotification, printInstance, unreadNotificationCount, updateUnreadNotificationCount, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const newRoadMap = SessionStorage.getKey(SessionVariables.NOTIFICATION_PARAMETERS);
    const history = useHistory();
    const isRoadmapItem = (_b = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.isRoadmapItem) !== null && _b !== void 0 ? _b : newRoadMap === null || newRoadMap === void 0 ? void 0 : newRoadMap.roadmap;
    const isAllNotification = (_d = (_c = history.location.state) === null || _c === void 0 ? void 0 : _c.isAllNotification) !== null && _d !== void 0 ? _d : newRoadMap === null || newRoadMap === void 0 ? void 0 : newRoadMap.allNotification;
    const isAllNotificationId = (_f = (_e = history.location.state) === null || _e === void 0 ? void 0 : _e.isAllNotificationId) !== null && _f !== void 0 ? _f : newRoadMap === null || newRoadMap === void 0 ? void 0 : newRoadMap.allNotificationId;
    const selectedItemId = (_h = (_g = history.location.state) === null || _g === void 0 ? void 0 : _g.selectedItemId) !== null && _h !== void 0 ? _h : newRoadMap === null || newRoadMap === void 0 ? void 0 : newRoadMap.roadmapId;
    const queryParams = (_j = history.location.state) === null || _j === void 0 ? void 0 : _j.queryParams;
    const url = (_l = (_k = history.location.state) === null || _k === void 0 ? void 0 : _k.url) !== null && _l !== void 0 ? _l : "";
    const u = !isEmpty(url) && new URL(url);
    !isEmpty(url) &&
        SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
            flagNotificationId: +(u === null || u === void 0 ? void 0 : u.searchParams.get("notificationId")),
            itemId: +(u === null || u === void 0 ? void 0 : u.searchParams.get("itemId")),
            recipientId: u === null || u === void 0 ? void 0 : u.searchParams.get("recipientId"),
            notificationDefId: +(u === null || u === void 0 ? void 0 : u.searchParams.get("defId")),
            frequency: +(u === null || u === void 0 ? void 0 : u.searchParams.get("frequency")),
        });
    useEffect(() => {
        if (isRoadmapItem && !isAllNotification) {
            SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
                roadmap: true,
                roadmapId: selectedItemId,
                allNotification: false,
                allNotificationId: null,
            });
        }
        if (isAllNotification && !isRoadmapItem) {
            SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
                allNotification: true,
                allNotificationId: isAllNotificationId,
                roadmap: false,
                roadmapId: null,
            });
        }
    });
    const { flagNotificationId, itemId, recipientId, notificationDefId, frequency, } = (_m = SessionStorage.getKey(SessionVariables.NOTIFICATION_PARAMETERS)) !== null && _m !== void 0 ? _m : {};
    const DEFAULT_TIME_FILTER = 0;
    const { handlePrint, nodeRef } = UsePrintPage(true, false);
    const sortOrderAlias = {
        ascend: "asc",
        descend: "desc",
    };
    const DEFAULT_STATE = Object.assign(Object.assign({ timeFilter: (_o = queryParams === null || queryParams === void 0 ? void 0 : queryParams.timeFilter) !== null && _o !== void 0 ? _o : null, inAppStatus: (_p = queryParams === null || queryParams === void 0 ? void 0 : queryParams.inAppStatus) !== null && _p !== void 0 ? _p : null, flagged: (_q = queryParams === null || queryParams === void 0 ? void 0 : queryParams.flagged) !== null && _q !== void 0 ? _q : null, pageNo: (_r = queryParams === null || queryParams === void 0 ? void 0 : queryParams.page) !== null && _r !== void 0 ? _r : 1, pageSize: (_s = queryParams === null || queryParams === void 0 ? void 0 : queryParams.limit) !== null && _s !== void 0 ? _s : null, category: (_t = queryParams === null || queryParams === void 0 ? void 0 : queryParams.category) !== null && _t !== void 0 ? _t : undefined, sortByCreatedDate: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.sort)
            ? sortOrderAlias[queryParams.order]
            : "desc" }, ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.fromDate) && {
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
    })), ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.subject) && {
        subject: queryParams.subject,
    }));
    const [filterValues, setFilterValues] = useState(DEFAULT_STATE);
    const [notificationModelVisibility, setnotificationModelVisibility] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [checkBoxGroupValue, setCheckBoxGroupValue] = useState([
        !isEmpty(queryParams === null || queryParams === void 0 ? void 0 : queryParams.flagged) && "flagged",
        !isEmpty(queryParams === null || queryParams === void 0 ? void 0 : queryParams.inAppStatus) && "Unread",
    ].filter(Boolean));
    // const [selecteDateRange, setDateRange] = useState(
    //   [
    //     queryParams?.fromDate && queryParams?.fromDate,
    //     queryParams?.toDate && queryParams?.toDate,
    //   ].filter(Boolean)
    // );
    const [notificationList, setNotificationList] = useState(undefined);
    const paginationProps = UseCustomPagination({
        totalRecords: notificationList === null || notificationList === void 0 ? void 0 : notificationList.totalRecords,
        stateParams: true,
    });
    useEffect(() => {
        if (paginationProps.pageSize) {
            setFilterValues((prev) => (Object.assign(Object.assign({}, prev), { pageSize: paginationProps.pageSize })));
        }
    }, [paginationProps.pageSize]);
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKNotificationReferenceType,
                LookupNames.LKNotificationTimeFilter,
            ],
            getLookups,
        });
        if (flagNotificationId && url) {
            InAppNotificationApi.getSingleNotification(flagNotificationId).then((response) => setSelectedNotification(response));
            if (selectedNotification) {
                // flagNotification(flagNotificationId).then((response) => {
                //   if (response) {
                //     if (response.ok) {
                //       notifications.success("Notification flagged successfully!");
                //     }
                //   }
                // });
                openNotificationModal(selectedNotification);
            }
        }
        const imagesToBePreloaded = [
            visionLogo,
            emailIcon,
            goToVision,
            feedbackNew,
            descriptionInfo,
            arrowIcon,
            activeArrow,
            beforeArrow,
        ];
        imagesToBePreloaded.forEach((image) => {
            new Image().src = image;
        });
    });
    useEffect(() => {
        if (notificationDefId && itemId && recipientId) {
            InAppNotificationApi.getRelatedNotificationsByFrequency(itemId, notificationDefId, frequency, filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageNo, filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageSize, filterValues.sortByCreatedDate === "asc"
                ? 1
                : filterValues.sortByCreatedDate === "desc"
                    ? 0
                    : null).then((res) => setNotificationList(res));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filterValues === null || filterValues === void 0 ? void 0 : filterValues.sortByCreatedDate,
        filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageNo,
        filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageSize,
    ]);
    useEffect(() => {
        if ((timeFilterOptions === null || timeFilterOptions === void 0 ? void 0 : timeFilterOptions.length) && isEmpty(filterValues.timeFilter)) {
            setFilterValues((prev) => (Object.assign(Object.assign({}, prev), { timeFilter: DEFAULT_TIME_FILTER })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeFilterOptions.length]);
    useEffect(() => {
        const payloadFilters = Object.assign({}, filterValues);
        payloadFilters.pageNo = (printInstance === null || printInstance === void 0 ? void 0 : printInstance.status) ? 1 : payloadFilters.pageNo;
        payloadFilters.pageSize = (printInstance === null || printInstance === void 0 ? void 0 : printInstance.status)
            ? // ? notificationList?.totalRecords   // To show All the Notifications in Print Modal
                payloadFilters.pageSize // To show Current Page Notification in Print Modal
            : payloadFilters.pageSize; //?? paginationProps.pageSize;
        if (isRoadmapItem && (filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageSize) && !isAllNotification) {
            InAppNotificationApi.getRelatedNotifications(selectedItemId, filterValues.sortByCreatedDate === "asc"
                ? 1
                : filterValues.sortByCreatedDate === "desc"
                    ? 0
                    : null, Object.assign(Object.assign({}, payloadFilters), { flagged: false, sortByCreatedDate: null, timeFilter: null })).then((response) => {
                if (!isEmpty(response)) {
                    setNotificationList(response);
                }
                if (printInstance === null || printInstance === void 0 ? void 0 : printInstance.status) {
                    printInstance === null || printInstance === void 0 ? void 0 : printInstance.resolveHandler();
                }
            });
        }
        if (isAllNotification &&
            (filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageSize) &&
            isRoadmapItem === false) {
            NotificationApi.getAllNotifications({
                timeFilter: null,
                inAppStatus: null,
                flagged: null,
                pageNo: payloadFilters.pageNo,
                pageSize: payloadFilters.pageSize,
                fromDate: null,
                toDate: null,
                category: null,
                sortByCreatedDate: "desc",
                subject: null,
                itemId: isAllNotificationId,
            }).then((response) => {
                if (!isEmpty(response)) {
                    setNotificationList(response);
                }
                if (printInstance === null || printInstance === void 0 ? void 0 : printInstance.status) {
                    printInstance === null || printInstance === void 0 ? void 0 : printInstance.resolveHandler();
                }
            });
        }
        if (!recipientId &&
            !isRoadmapItem &&
            !isAllNotification &&
            !isEmpty(filterValues === null || filterValues === void 0 ? void 0 : filterValues.timeFilter) &&
            (filterValues === null || filterValues === void 0 ? void 0 : filterValues.pageSize) &&
            ((filterValues === null || filterValues === void 0 ? void 0 : filterValues.timeFilter) === timeFilterOptionType.Custom
                ? filterValues.fromDate
                : true)) {
            InAppNotificationApi.getNotificationList(payloadFilters).then((response) => {
                if (!isEmpty(response)) {
                    setNotificationList(response);
                }
                if (printInstance === null || printInstance === void 0 ? void 0 : printInstance.status) {
                    printInstance === null || printInstance === void 0 ? void 0 : printInstance.resolveHandler();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, printInstance, unreadNotificationCount]);
    useEffect(() => {
        if (flagNotificationId ||
            recipientId ||
            isRoadmapItem ||
            isAllNotification) {
            window.addEventListener("beforeunload", (event) => {
                window.history.replaceState({}, document.title);
                SessionStorage.removeKey(SessionVariables.NOTIFICATION_PARAMETERS);
            });
        }
    });
    const handleSelectCategoryorTime = (inputName, selectedValue) => {
        const updateValues = {
            [inputName]: isEmpty(selectedValue) ? undefined : selectedValue,
        };
        setFilterValues((prev) => (Object.assign(Object.assign({}, prev), updateValues)));
        generateQueryParams(updateValues);
    };
    const handleCheckBoxGroup = (value) => {
        setCheckBoxGroupValue(value);
        const queryObject = {};
        queryObject.flagged = value.includes("flagged") ? 1 : undefined;
        queryObject.inAppStatus = value.includes("Unread") ? 0 : undefined;
        generateQueryParams(queryObject);
    };
    // const handleDateRangeSelect = (_, dateString: string[]) => {
    //   const selectedDateRange = dateString.filter(Boolean);
    //   setDateRange(selectedDateRange);
    //   const updateFilterValues = {
    //     timeFilter: filterValues.timeFilter,
    //     fromDate: selectedDateRange[0],
    //     toDate: selectedDateRange[1],
    //   };
    //   generateQueryParams(updateFilterValues);
    // };
    const generateQueryParams = (filterValues) => {
        const query = Object.assign(Object.assign(Object.assign({}, queryParams), filterValues), { page: 1 });
        if (!isEmpty(filterValues.timeFilter)) {
            if ((filterValues === null || filterValues === void 0 ? void 0 : filterValues.timeFilter) !== timeFilterOptionType.Custom) {
                delete query["fromDate"];
                delete query["toDate"];
            }
            else if (!filterValues.fromDate) {
                return;
            }
        }
        history.push(history.location.pathname, {
            queryParams: JSON.parse(JSON.stringify(query)),
        });
    };
    const handleRefresh = (event) => {
        event.preventDefault();
        SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
            roadmap: undefined,
            roadmapId: undefined,
        });
        history.push(history.location.pathname, !isEmpty(queryParams) && {
            queryParams: JSON.parse(JSON.stringify(queryParams)),
        });
    };
    const dispatch = useDispatch();
    const readNotificationHandler = (data) => {
        const temp = Object.assign(Object.assign({}, notificationList), { notificationsList: [...notificationList.notificationsList].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { inAppStatus: InAppStatus.read })) });
        setNotificationList(temp);
        readNotification(data).then((response) => {
            // console.log(response);
            if (response) {
                updateUnreadNotificationCount(unreadNotificationCount - 1);
            }
        });
    };
    const unreadNotificationHandler = (data) => {
        const temp = Object.assign(Object.assign({}, notificationList), { notificationsList: [...notificationList.notificationsList].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { inAppStatus: InAppStatus.unread })) });
        setNotificationList(temp);
        unreadNotification(data).then((response) => {
            if (response) {
                updateUnreadNotificationCount(unreadNotificationCount + 1);
            }
        });
    };
    const flagNotificationHandler = (data) => {
        flagNotification(data).then((response) => {
            if (response) {
                if (response.ok) {
                    notifications.success("Notification flagged successfully!");
                    const temp = Object.assign(Object.assign({}, notificationList), { notificationsList: [...notificationList.notificationsList].map((el) => (el.id !== data ? el : Object.assign(Object.assign({}, el), { flagged: true }))) });
                    setNotificationList(JSON.parse(JSON.stringify(temp)));
                    if (selectedNotification) {
                        setSelectedNotification(Object.assign(Object.assign({}, selectedNotification), { flagged: true }));
                    }
                }
            }
        });
    };
    const unflagNotificationHandler = (data) => {
        unflagNotification(data).then((response) => {
            if (response) {
                if (response.ok) {
                    notifications.success("Notification unflagged successfully!");
                    const temp = Object.assign(Object.assign({}, notificationList), { notificationsList: [...notificationList.notificationsList].map((el) => (el.id !== data ? el : Object.assign(Object.assign({}, el), { flagged: false }))) });
                    setNotificationList(temp);
                    if (selectedNotification) {
                        setSelectedNotification(Object.assign(Object.assign({}, selectedNotification), { flagged: false }));
                    }
                }
            }
        });
    };
    const openNotificationModal = (notification) => {
        (notification === null || notification === void 0 ? void 0 : notification.inAppStatus) === InAppStatus.unread &&
            readNotificationHandler(notification.id);
        InAppNotificationApi.getSingleNotification(notification.id)
            .then((response) => {
            setSelectedNotification(response);
            setnotificationModelVisibility(true);
        })
            .catch((error) => {
            throw error;
        });
    };
    const closeNotificationModel = () => setnotificationModelVisibility(false);
    const filters = (React.createElement("div", { className: "row d-inline-flex w-100 filter-wrapper", style: { alignItems: "center" } },
        React.createElement("div", { className: "col-3" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["Notification.category"]),
                        " ")),
                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: filterValues === null || filterValues === void 0 ? void 0 : filterValues.category, onChange: handleSelectCategoryorTime, inputName: "category", localizationKey: ["Notification.category"], lookUpType: LookupNames.LKNotificationReferenceType, placeHolder: localize(["Notification.all"]), allowClear: true, disabled: recipientId || isRoadmapItem || isAllNotification }))),
        React.createElement("div", { className: "col-1 col-sm-3" },
            React.createElement(Checkbox.Group, { onChange: handleCheckBoxGroup, className: "checkbox-group-wrapper checkBox-rangePicker", value: checkBoxGroupValue },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Checkbox, { value: "Unread", disabled: recipientId || isRoadmapItem || isAllNotification }, "Unread")),
                    React.createElement(Col, { span: 24 },
                        React.createElement(Checkbox, { value: "flagged", disabled: recipientId || isRoadmapItem || isAllNotification }, "Flagged Only")))),
            React.createElement(Button, { style: { verticalAlign: "text-top", height: "36px" }, htmlType: "button", type: "primary", hidden: !recipientId && !isRoadmapItem && !isAllNotification, onClick: () => {
                    SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
                        roadmap: undefined,
                        roadmapId: undefined,
                        allNotification: undefined,
                        allNotificationId: undefined,
                    });
                    window.location.reload();
                } }, "Reset"))));
    return (React.createElement("div", { ref: nodeRef, className: "inAppNotificationsListing", key: `${printInstance.status}` },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, { clientSide: false }),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Button, { htmlType: "button", type: "primary", onClick: handleRefresh },
                    React.createElement(RenderSvg, { icon: "Refresh" }))) }),
        filters,
        isEmpty(notificationList) ? (React.createElement("div", { style: { visibility: "hidden" } },
            React.createElement(InAppNotificationTable, { data: [
                    {
                        id: 66504,
                        recipientId: "NumanDelphi",
                        recipientEmailAddress: "numan@mailinator.com",
                        companyId: 0,
                        status: 3,
                        statusText: "Failed",
                        inAppStatus: 0,
                        inAppStatusText: "Unread",
                        subject: "User Creation: ZainAbideen-CCQSGroup2 has been created in CCQS Group2",
                        body: '<!DOCTYPE html>\r\n<html>\r\n  <head>\r\n    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />\r\n  </head>\r\n  <body>\r\n    <p>Dear CCQS,</p>\r\n    <p>New User ZainAbideen-CCQSGroup2 has been created in CCQS Group2 by superuser.</p>\r\n    <p>Regards,</p>\r\n    <p>RodaBI by CCQS</p>\r\n  </body>\r\n</html>\r\n\r\n',
                        sentDateTime: null,
                        flagged: false,
                        createdDate: "2022-03-02T13:59:59.379+0000",
                    },
                ], paginationProps: {
                    currentPage: 1,
                    pageSize: filterValues.pageSize,
                    totalCount: 1,
                    triggerPagination: !(printInstance === null || printInstance === void 0 ? void 0 : printInstance.status),
                }, paginationSection: paginationProps, handleRowClick: openNotificationModal, flagNotification: flagNotificationHandler, unflagNotification: unflagNotificationHandler, readNotification: readNotificationHandler, unreadNotification: unreadNotificationHandler }))) : (React.createElement(React.Fragment, null,
            React.createElement(InAppNotificationTable, { data: (_u = notificationList === null || notificationList === void 0 ? void 0 : notificationList.notificationsList) !== null && _u !== void 0 ? _u : [], paginationProps: {
                    currentPage: filterValues.pageNo,
                    pageSize: filterValues.pageSize,
                    totalCount: notificationList === null || notificationList === void 0 ? void 0 : notificationList.totalRecords,
                    triggerPagination: !(printInstance === null || printInstance === void 0 ? void 0 : printInstance.status),
                }, paginationSection: paginationProps, handleRowClick: openNotificationModal, flagNotification: flagNotificationHandler, unflagNotification: unflagNotificationHandler, readNotification: readNotificationHandler, unreadNotification: unreadNotificationHandler }))),
        React.createElement(NotificationModel, { visible: notificationModelVisibility, onClose: closeNotificationModel, selectedNotification: selectedNotification, flagNotification: flagNotificationHandler, unflagNotification: unflagNotificationHandler })));
};
const mapDispatchToProps = {
    getLookups,
    flagNotification,
    unflagNotification,
    unreadNotification,
    readNotification,
    updateUnreadNotificationCount,
};
function mapStateTopProps(state) {
    var _a, _b;
    return {
        timeFilterOptions: (_b = (_a = state.lookups[LookupNames.LKNotificationTimeFilter]) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a === null || a === void 0 ? void 0 : a.id) - (b === null || b === void 0 ? void 0 : b.id))) !== null && _b !== void 0 ? _b : [],
        printInstance: state.togglePrint,
        unreadNotificationCount: state.unreadNotificationCount,
    };
}
export default connect(mapStateTopProps, mapDispatchToProps)(InAppNotificationListContainer);
