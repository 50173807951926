import React from 'react';
import CustomTable from '../../../../../layout/components/customTable';
const AuditLogsUserRolesTable = ({ data }) => {
    const columns = [
        {
            fieldName: "groupName",
            title: ["Group.group"],
            sortable: false,
            width: 150,
            render: (groupName, item) => (React.createElement("span", null,
                " ", groupName !== null && groupName !== void 0 ? groupName : "N/A")),
        },
        {
            fieldName: "companyName",
            title: ["Company.company"],
            sortable: false,
            width: 150,
            render: (companyName, item) => (React.createElement("span", null,
                " ", companyName !== null && companyName !== void 0 ? companyName : "N/A")),
        },
        {
            fieldName: "siteName",
            title: ["Site.site"],
            sortable: false,
            width: 150,
            render: (siteName, item) => (React.createElement("span", null,
                " ", siteName !== null && siteName !== void 0 ? siteName : "N/A")),
        },
        {
            fieldName: "item",
            title: ["UserRole.role"],
            sortable: false,
            width: 150,
            render: (item, object) => (React.createElement("span", null,
                " ",
                item === "SUPER_ROLE" ? "Super Role" : item)),
        }
    ];
    return (React.createElement("div", { className: "customListing" },
        React.createElement(CustomTable, { data: data, columns: columns, scroll: false, dataType: "any", rowClickHandler: undefined })));
};
export default AuditLogsUserRolesTable;
