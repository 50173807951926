import { Button, DatePicker, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { AuditLogsApi } from '../../../../core/api/auditLogsApi';
import { RoleApi } from '../../../../core/api/roleApi';
import { defaultDateTimeFormat } from '../../../../core/constants/DateTime';
import { LookupNames } from '../../../../core/constants/lookup';
import { getLookupByListing } from '../../../../core/helpers/lookupHanlder';
import useDidMount from '../../../../core/hooks/useDidMount';
import localize from '../../../../core/utils/translation-files';
import Header from "../../../../layout/components/common/header";
import CustomModal from '../../../../layout/components/customModal';
import UseCustomPagination from '../../../../layout/components/hooks/useCustomPagination';
import RenderSvg from '../../../../layout/components/icons/RenderSvg';
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import { getAuditLogs } from '../../../../redux/auditLogs/actions';
import { getLookups } from '../../../../redux/lookup/actions';
import AuditLogsItemResponseTable from './components/AuditLogsItemResponseTable';
import AuditLogsTable from './components/AuditLogsTable';
import AuditLogsUserRolesTable from './components/AuditLogsUserRolesTable';
import './styles.scss';
import ReadMoreLess from '../../../../layout/components/ReadMoreLess';
import isEmpty from '../../../../core/helpers/isEmpty';
const AuditLogsListingPage = ({ getLookups, getAuditLogs, printInstance }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const history = useHistory();
    const queryParams = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.queryParams;
    const [auditLogsData, setAuditLogsData] = useState();
    const [auditLogItemData, setAuditLogItemData] = useState();
    const [openItemModal, setOpenItemModal] = useState(false);
    const [openRoleModal, setOpenRoleModal] = useState(false);
    const [viewPortWidthRole, setViewPortWidthRole] = useState(0);
    const [viewPortWidthItemAffected, setViewPortWidthItemAffected] = useState(0);
    const paginationProps = UseCustomPagination({
        totalRecords: auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.totalElements,
        stateParams: true,
    });
    const sortOrderAlias = {
        ascend: "asc",
        descend: "desc",
    };
    const initialFilterState = {
        startDateAndTime: (_b = queryParams === null || queryParams === void 0 ? void 0 : queryParams.startDateAndTime) !== null && _b !== void 0 ? _b : null,
        endDateAndTime: (_c = queryParams === null || queryParams === void 0 ? void 0 : queryParams.endDateAndTime) !== null && _c !== void 0 ? _c : null,
        firstName: (_d = queryParams === null || queryParams === void 0 ? void 0 : queryParams.firstName) !== null && _d !== void 0 ? _d : '',
        lastName: (_e = queryParams === null || queryParams === void 0 ? void 0 : queryParams.lastName) !== null && _e !== void 0 ? _e : '',
        activityId: (_f = queryParams === null || queryParams === void 0 ? void 0 : queryParams.activityId) !== null && _f !== void 0 ? _f : null,
        pageNo: (_g = queryParams === null || queryParams === void 0 ? void 0 : queryParams.page) !== null && _g !== void 0 ? _g : 1,
        pageSize: (_h = queryParams === null || queryParams === void 0 ? void 0 : queryParams.limit) !== null && _h !== void 0 ? _h : paginationProps.pageSize,
        sortColumn: (_j = queryParams === null || queryParams === void 0 ? void 0 : queryParams.sort) !== null && _j !== void 0 ? _j : "dateTime",
        sortOrder: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.sort) ? sortOrderAlias[queryParams.order] : "desc",
    };
    const [filters, setFilters] = useState(initialFilterState);
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKAuditActivityFilter],
            getLookups,
        });
    });
    useEffect(() => {
        if (paginationProps.pageSize) {
            setFilters((prev) => (Object.assign(Object.assign({}, prev), { pageSize: paginationProps.pageSize })));
        }
    }, [paginationProps.pageSize]);
    const handleGetAuditLogsDataCall = (filters, fieldName, isMounted = true) => {
        getAuditLogs(filters)
            .then((res) => {
            if (isMounted) {
                setAuditLogsData(res);
                if (fieldName) {
                    document.getElementById(fieldName).focus();
                }
            }
        })
            .catch((err) => {
            console.log(err);
        });
    };
    useEffect(() => {
        let isMounted = true;
        filters.pageSize !== 0 && handleGetAuditLogsDataCall(filters, undefined, isMounted);
        return () => {
            isMounted = false;
        };
    }, [filters.pageSize]);
    useLayoutEffect(() => {
        const handleSetViewPortWidthRole = () => {
            var _a, _b, _c;
            if (((_a = auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.auditLogs) === null || _a === void 0 ? void 0 : _a.length) && document.getElementsByTagName("tbody")[0]) {
                const tableBody = document.getElementsByTagName("tbody")[0];
                const roleColumn = (_b = tableBody === null || tableBody === void 0 ? void 0 : tableBody.childNodes[0]) === null || _b === void 0 ? void 0 : _b.childNodes[2];
                const viewPortWidthRole = (_c = roleColumn === null || roleColumn === void 0 ? void 0 : roleColumn.getBoundingClientRect().width) !== null && _c !== void 0 ? _c : 220;
                let fontConstant = 10;
                if (viewPortWidthRole <= 220) {
                    fontConstant = 13;
                }
                else if (viewPortWidthRole < 250) {
                    fontConstant = 11;
                }
                else if (viewPortWidthRole < 350) {
                    fontConstant = 10;
                }
                setViewPortWidthRole((viewPortWidthRole) / fontConstant);
            }
        };
        const handleSetViewPortWidthItemAffected = () => {
            var _a, _b, _c;
            if (((_a = auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.auditLogs) === null || _a === void 0 ? void 0 : _a.length) && document.getElementsByTagName("tbody")[0]) {
                const tableBody = document.getElementsByTagName("tbody")[0];
                const itemAffectedColumn = (_b = tableBody === null || tableBody === void 0 ? void 0 : tableBody.childNodes[0]) === null || _b === void 0 ? void 0 : _b.childNodes[4];
                const viewPortWidthItemAffected = (_c = itemAffectedColumn === null || itemAffectedColumn === void 0 ? void 0 : itemAffectedColumn.getBoundingClientRect().width) !== null && _c !== void 0 ? _c : 350;
                let fontConstant = 5;
                if (viewPortWidthItemAffected < 350) {
                    fontConstant = 13;
                }
                else if (viewPortWidthItemAffected < 450) {
                    fontConstant = 10;
                }
                else if (viewPortWidthItemAffected < 550) {
                    fontConstant = 7;
                }
                setViewPortWidthItemAffected(viewPortWidthItemAffected / fontConstant);
            }
        };
        handleSetViewPortWidthRole();
        handleSetViewPortWidthItemAffected();
        window.addEventListener("resize", (event) => handleSetViewPortWidthRole());
        window.addEventListener("resize", (event) => handleSetViewPortWidthItemAffected());
    }, [auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.auditLogs]);
    const generateQueryParams = (filterValues) => {
        const query = Object.assign(Object.assign(Object.assign({}, queryParams), filterValues), { page: 1 });
        history.push(history.location.pathname, {
            queryParams: JSON.parse(JSON.stringify(query)),
        });
    };
    const resetQueryParams = () => {
        history.push(history.location.pathname, {
            queryParams: {},
        });
    };
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { [name]: value })));
    };
    const handleStartDateTimeChange = (selectedDate) => {
        setFilters(Object.assign(Object.assign({}, filters), { startDateAndTime: selectedDate ? selectedDate : '' }));
    };
    const handleEndDateTimeChange = (selectedDate) => {
        setFilters(Object.assign(Object.assign({}, filters), { endDateAndTime: selectedDate ? selectedDate : '' }));
    };
    const handleActivityIdChange = (activityId) => {
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { activityId })));
    };
    const handleFilterReset = () => {
        setFilters(initialFilterState);
        resetQueryParams();
        handleGetAuditLogsDataCall(initialFilterState, undefined);
    };
    const handleFilterApply = (fieldName) => {
        setFilters(Object.assign(Object.assign({}, filters), { pageNo: 1, pageSize: filters.pageSize }));
        generateQueryParams(filters);
        handleGetAuditLogsDataCall(filters, fieldName);
    };
    const handleRefresh = (event) => {
        event.preventDefault();
        history.push(history.location.pathname, !isEmpty(queryParams) && {
            queryParams: JSON.parse(JSON.stringify(queryParams)),
        });
    };
    const openItemDetailsModal = (audit) => {
        setOpenItemModal(true);
        AuditLogsApi.getAuditLogItemData(audit.id)
            .then((res) => {
            setAuditLogItemData(res);
        })
            .catch(e => console.log(e));
    };
    const openRoleDetailsModal = (audit) => {
        setOpenRoleModal(true);
        RoleApi.getUserCompanyRolesByUsername(audit.username)
            .then((res) => {
            setAuditLogItemData(res);
        })
            .catch(e => console.log(e));
    };
    const closeModals = () => {
        setAuditLogItemData(undefined);
        setOpenItemModal(false);
        setOpenRoleModal(false);
    };
    const handleKeyDown = (event, fieldName) => {
        if (event.key === 'Enter') {
            handleFilterApply(fieldName);
        }
    };
    const validateEndDateAndTime = () => {
        if (filters.startDateAndTime && filters.endDateAndTime) {
            const startDate = moment(filters.startDateAndTime);
            const endDate = moment(filters.endDateAndTime);
            return endDate.isBefore(startDate);
        }
        return false;
    };
    const disabledStartDate = (current) => {
        const endDate = filters.endDateAndTime ? moment(filters.endDateAndTime) : null;
        const today = moment().endOf('day'); // Restrict past today
        // If end date is selected, allow the same day but restrict time.
        if (endDate) {
            return current && (current > today || current.isAfter(endDate, 'day'));
        }
        // Otherwise, disable dates after today
        return current && current > today;
    };
    const disabledEndDate = (current) => {
        const startDate = filters.startDateAndTime ? moment(filters.startDateAndTime) : null;
        const today = moment().endOf('day'); // Restrict past today
        // If start date is selected, allow the same day but restrict time.
        if (startDate) {
            return current && (current > today || current.isBefore(startDate, 'day'));
        }
        // Otherwise, disable dates after today
        return current && current > today;
    };
    const filtersBar = (React.createElement("div", { className: "row w-100 filter-wrapper audit_log_filters" },
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["AuditLogs.startDateAndTime"]))),
                React.createElement(DatePicker, { "data-testid": "start-date-picker", id: 'startDateAndTime', name: "startDateAndTime", format: defaultDateTimeFormat, showTime: true, disabledDate: disabledStartDate, placeholder: localize(["AuditLogs.startDateAndTime"]), value: filters.startDateAndTime ? moment((_k = filters.startDateAndTime) !== null && _k !== void 0 ? _k : null) : null, className: `mb-2 mb-md-0 d-block ant-input`, onChange: handleStartDateTimeChange, onSelect: handleStartDateTimeChange, onKeyDown: (e) => handleKeyDown(e, "startDateAndTime") }))),
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["AuditLogs.endDateAndTime"]))),
                React.createElement(DatePicker, { "data-testid": "end-date-picker", id: 'endDateAndTime', format: defaultDateTimeFormat, name: "endDateAndTime", showTime: true, disabledDate: disabledEndDate, placeholder: localize(["AuditLogs.endDateAndTime"]), value: filters.endDateAndTime ? moment((_l = filters.endDateAndTime) !== null && _l !== void 0 ? _l : null) : null, className: `mb-2 mb-md-0 d-block ant-input`, onChange: handleEndDateTimeChange, onSelect: handleEndDateTimeChange, onKeyDown: (e) => handleKeyDown(e, "endDateAndTime") }),
                (validateEndDateAndTime()) && (React.createElement("p", { className: "error", "data-testid": "end-date-error" }, "End date must be after start date.")))),
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["AuditLogs.firstName"]),
                        " ")),
                React.createElement(Input, { id: 'firstName', style: { backgroundColor: '#fff' }, type: "text", name: "firstName", placeholder: localize(["AuditLogs.firstName"]), onChange: handleFilterChange, value: filters.firstName, allowClear: true, onKeyDown: (e) => handleKeyDown(e, "firstName") }),
                React.createElement("p", null, " "))),
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["AuditLogs.lastName"]),
                        " ")),
                React.createElement(Input, { id: 'lastName', style: { backgroundColor: '#fff' }, type: "text", name: "lastName", placeholder: localize(["AuditLogs.lastName"]), onChange: handleFilterChange, value: filters.lastName, allowClear: true, onKeyDown: (e) => handleKeyDown(e, "lastName") }),
                React.createElement("p", null, " "))),
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["AuditLogs.activity"]),
                        " ")),
                React.createElement(LookupDropDown, { id: 'activity', className: 'bg_white', selectedValue: filters === null || filters === void 0 ? void 0 : filters.activityId, onChange: value => {
                        handleActivityIdChange(value);
                    }, localizationKey: ["AuditLogs.activity"], lookUpType: LookupNames.LKAuditActivityFilter, placeHolder: localize(["AuditLogs.activity"]), onKeyDown: (event, key) => handleKeyDown(event, "activity"), allowClear: true }),
                React.createElement("p", null, " "))),
        React.createElement("div", { className: "col-lg-2 col-md-4 col-sm-12" },
            React.createElement("div", { className: "audit_filter_btn_container" },
                React.createElement(Button, { htmlType: "button", type: "default", onClick: handleFilterReset }, localize(["Button.reset"])),
                React.createElement(Button, { htmlType: "button", type: "primary", onClick: () => handleFilterApply(undefined), disabled: validateEndDateAndTime() }, localize(["Button.apply"]))),
            React.createElement("p", null, " "))));
    const renderRoleReadMoreLess = (role) => {
        return (React.createElement(ReadMoreLess, { text: role, characterLimit: viewPortWidthRole }));
    };
    const renderItemAffectedReadMoreLess = (itemAffected) => {
        return (React.createElement(ReadMoreLess, { text: itemAffected, characterLimit: viewPortWidthItemAffected }));
    };
    return (React.createElement("div", { className: 'audit_logs_listing_table' },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(Button, { htmlType: "button", type: "primary", onClick: handleRefresh },
                    React.createElement(RenderSvg, { icon: "Refresh" }))) }),
        filtersBar,
        React.createElement(AuditLogsTable, { data: (_m = auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.auditLogs) !== null && _m !== void 0 ? _m : [], paginationProps: {
                currentPage: filters.pageNo,
                pageSize: filters.pageSize,
                totalCount: auditLogsData === null || auditLogsData === void 0 ? void 0 : auditLogsData.totalElements,
                triggerPagination: !(printInstance === null || printInstance === void 0 ? void 0 : printInstance.status),
            }, key: String(viewPortWidthRole), paginationSection: paginationProps, handleRowClick: null, openRoleDetailsModal: openRoleDetailsModal, openItemDetailsModal: openItemDetailsModal, renderRoleReadMoreLess: renderRoleReadMoreLess, renderItemAffectedReadMoreLess: renderItemAffectedReadMoreLess }),
        openItemModal &&
            React.createElement(CustomModal, { visible: openItemModal, maskClosable: false, onCancel: closeModals, destroyOnClose: true, footer: null, title: ["Affected", "item", "details"], overflowY: false, width: "60%" },
                React.createElement(AuditLogsItemResponseTable, { data: auditLogItemData })),
        openRoleModal &&
            React.createElement(CustomModal, { visible: openRoleModal, maskClosable: false, onCancel: closeModals, destroyOnClose: true, footer: null, title: ["User.user", "Role.roles"], overflowY: false, width: "60%" },
                React.createElement(AuditLogsUserRolesTable, { data: auditLogItemData }))));
};
const mapDispatchToProps = {
    getLookups,
    getAuditLogs
};
function mapStateTopProps(state) {
    return {
        printInstance: state.togglePrint
    };
}
export default connect(mapStateTopProps, mapDispatchToProps)(AuditLogsListingPage);
