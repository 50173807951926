import React from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import localize from "../../../core/utils/translation-files";
import isEmpty from "../../../core/helpers/isEmpty";
import UseToggleStateOnScroll from "../../../core/hooks/useToggleStateOnScroll";
const Option = Select.Option;
const LookupDropDown = ({ selectedValue, onChange, defaultValue, className = "mb-lg-0 mb-4", disabled = false, labelInValue = false, allowClear = false, textProperty = "text", valueProperty = "code", lookUpType, inputName, error, valueType = "number", showSearch = true, mode, customOnChangeHandler = false, localizationKey, placeHolder, onKeyDown = undefined, id = undefined, }) => {
    const optionFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
    let data = useSelector((state) => { var _a; return (_a = state.lookups[lookUpType]) !== null && _a !== void 0 ? _a : []; });
    const handleChange = (value) => {
        let updatedValue = mode === "multiple" ? value : value !== null && value !== void 0 ? value : "";
        if (customOnChangeHandler) {
            customOnChangeHandler(value);
        }
        else if (inputName) {
            onChange(inputName, updatedValue);
            return;
        }
        else {
            onChange(updatedValue);
        }
    };
    const { visible, setVisible } = UseToggleStateOnScroll();
    const handleKeyDown = (event) => {
        if (onKeyDown) {
            const activeOption = document.querySelector('.ant-select-item-option-active');
            if (activeOption) {
                const optionValue = activeOption.getAttribute('title');
                let optionKey = data.find(item => item.text === optionValue).code;
                optionKey = valueType === "number" ? parseInt(optionKey) : optionKey;
                if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
                    onKeyDown(event, optionKey, optionValue);
                }
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { id: id, onKeyDown: handleKeyDown, "data-testid": "single-select", labelInValue: labelInValue, allowClear: allowClear, disabled: disabled, showSearch: showSearch, className: `${className} ${error ? `require-ant-select` : ``}`, mode: mode, defaultValue: mode === "multiple" && selectedValue === ""
                ? []
                : isEmpty(selectedValue)
                    ? undefined
                    : selectedValue, value: mode === "multiple" && selectedValue === ""
                ? []
                : isEmpty(selectedValue)
                    ? undefined
                    : selectedValue, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : localize(["selectA", ...localizationKey]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleChange, open: visible, onDropdownVisibleChange: setVisible, key: `select-${localizationKey[0]}` }, data.map((option, key) => (React.createElement(Option, { key: option[valueProperty], value: valueType === "number"
                ? parseInt(option[valueProperty])
                : option[valueProperty], id: "option-div-container" }, option[textProperty])))),
        error && React.createElement("p", { className: "error" }, error)));
};
const Memoize = React.memo(LookupDropDown);
export default Memoize;
