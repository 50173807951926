import React from 'react';
import CustomTable from '../../../../../layout/components/customTable';
import localize from '../../../../../core/utils/translation-files';
const AuditLogsItemResponseTable = ({ data }) => {
    var _a, _b, _c, _d;
    const propertyChanges = (data === null || data === void 0 ? void 0 : data.propertyChanges) ? JSON.parse(data === null || data === void 0 ? void 0 : data.propertyChanges) : [];
    const changedPropertiesColumns = [
        {
            fieldName: "propertyDisplayName",
            title: ["Updates"],
            sortable: false,
            width: 200,
            render: (propertyDisplayName, item) => (React.createElement("span", null,
                " ", propertyDisplayName !== null && propertyDisplayName !== void 0 ? propertyDisplayName : "N/A")),
        },
        {
            fieldName: "oldValue",
            title: ["Old", "Value"],
            sortable: false,
            width: 150,
            render: (oldValue, item) => (React.createElement("span", { style: { whiteSpace: 'normal' } },
                " ", oldValue !== null && oldValue !== void 0 ? oldValue : "")),
        },
        {
            fieldName: "newValue",
            title: ["New", "Value"],
            sortable: false,
            width: 150,
            render: (newValue, item) => (React.createElement("span", { style: { whiteSpace: 'normal' } },
                " ", newValue !== null && newValue !== void 0 ? newValue : "")),
        }
    ];
    return (React.createElement("div", { className: "customListing" },
        React.createElement("div", { className: "row", style: { alignItems: "center", margin: '0px' } },
            React.createElement("div", { className: "col-6" },
                React.createElement("div", { className: "text_container" },
                    React.createElement("div", { className: "item_title" }, localize(["Group.group"])),
                    React.createElement("div", { className: "item_value" }, (_a = data === null || data === void 0 ? void 0 : data.groupName) !== null && _a !== void 0 ? _a : "N/A"))),
            React.createElement("div", { className: "col-6" },
                React.createElement("div", { className: "text_container" },
                    React.createElement("div", { className: "item_title" }, localize(["Company.company"])),
                    React.createElement("div", { className: "item_value" }, (_b = data === null || data === void 0 ? void 0 : data.companyName) !== null && _b !== void 0 ? _b : "N/A")))),
        React.createElement("div", { className: "row", style: { alignItems: "center", marginTop: '10px' } },
            React.createElement("div", { className: "col-6", style: { paddingLeft: '22px' } },
                React.createElement("div", { className: "text_container" },
                    React.createElement("div", { className: "item_title" }, localize(["Site.site"])),
                    React.createElement("div", { className: "item_value" }, (_c = data === null || data === void 0 ? void 0 : data.siteName) !== null && _c !== void 0 ? _c : "N/A"))),
            React.createElement("div", { className: "col-6" },
                React.createElement("div", { className: "text_container" },
                    React.createElement("div", { className: "item_title" }, localize(["AuditLogs.item"])),
                    React.createElement("div", { className: "item_value" }, (_d = data === null || data === void 0 ? void 0 : data.item) !== null && _d !== void 0 ? _d : "N/A")))),
        propertyChanges.length > 0 &&
            React.createElement("div", { className: "change_properties_table" },
                React.createElement(CustomTable, { data: propertyChanges, columns: changedPropertiesColumns, dataType: "any", scroll: propertyChanges.length > 5, rowClickHandler: undefined }))));
};
export default AuditLogsItemResponseTable;
