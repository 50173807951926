import { httpDelete, httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "admin/role";
const companyControllerEndpoint = "admin/company";
export class RoleApi {
    static getRoles(companyId) {
        return httpGet(`${companyControllerEndpoint}/${companyId}/role`);
    }
    static getUserAllowedRoles() {
        return httpGet(`admin/user/allowedRoles`);
    }
    static getUserCompanyRolesByUsername(username) {
        return httpPost(`${controllerEndpoint}/userCompanyRoles`, { body: username });
    }
    static createRole(data) {
        return httpPost(controllerEndpoint, { body: data });
    }
    static updateRolePermissions(data) {
        return httpPut(`${controllerEndpoint}`, { body: data });
    }
    static deleteRole(data) {
        return httpDelete(`${controllerEndpoint}/${data}`);
    }
    static deleteRolePermission(data) {
        return httpDelete(`${controllerEndpoint}/${data}`);
    }
}
