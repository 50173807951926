import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "support/audit";
export class AuditLogsApi {
    static getAuditLogsData(filter) {
        return httpPost(controllerEndpoint + "/" + "getAllLogs", { body: filter });
    }
    static getAuditLogItemData(key) {
        return httpGet(controllerEndpoint + "/" + "getItemDetails/" + key);
    }
}
