import React, { useContext } from 'react';
import { defaultExpandableTableScrollPosition } from '../../../../../core/constants/tableScrollConstants';
import { printContext } from '../../../../../core/contexts/printContext';
import { getLocalDateString } from '../../../../../core/helpers/dateFormatter';
import CustomTable from '../../../../../layout/components/customTable';
import TableActions from '../../../../../layout/components/customTable/tableActions';
import store from '../../../../../redux/configureStore';
import RenderSvg from '../../../../../layout/components/icons/RenderSvg';
import localize from '../../../../../core/utils/translation-files';
const AuditLogsTable = ({ data, handleRowClick, paginationProps, paginationSection, actions = null, openRoleDetailsModal, openItemDetailsModal, renderRoleReadMoreLess, renderItemAffectedReadMoreLess }) => {
    const lookupState = store.getState().lookups.LKAuditActivityFilter;
    const { nodeRef } = useContext(printContext);
    const columns = [
        {
            fieldName: "dateTime",
            title: ["AuditLogs.DateTime"],
            sortable: data.length > 0,
            width: 180,
            render: (dateTime, item) => (React.createElement("span", { style: { fontWeight: "bold" } },
                " ",
                getLocalDateString(dateTime))),
        },
        {
            fieldName: "firstName",
            title: ["AuditLogs.user"],
            sortable: data.length > 0,
            width: 200,
            render: (firstName, item) => (React.createElement("span", { style: { fontWeight: "bold" } },
                " ",
                `${item.firstName} ${item.lastName}`)),
        },
        {
            fieldName: "roles",
            title: ["AuditLogs.role"],
            sortable: false,
            width: 220,
            render: (roles, item) => (React.createElement("span", null,
                " ",
                React.createElement("div", { className: 'affected_item_wrapper', style: { whiteSpace: 'normal' } },
                    React.createElement(RenderSvg, { className: "affected_item_action_button", icon: "InfoMini", onClick: openRoleDetailsModal.bind({}, item), tooltipTitle: localize(["View", "Roles"]) }),
                    React.createElement("div", { className: 'audit_more_or_less' }, renderRoleReadMoreLess(roles === null || roles === void 0 ? void 0 : roles.map(role => role === "SUPER_ROLE" ? "Super Role" : role).join(" | ")))))),
        },
        {
            fieldName: "auditEvent",
            title: ["AuditLogs.activity"],
            sortable: data.length > 0,
            width: 200,
            render: (auditEvent, item) => {
                var _a;
                return (React.createElement("span", null,
                    " ", (_a = lookupState.filter((activity) => activity.code == auditEvent).find((activity) => activity)) === null || _a === void 0 ? void 0 :
                    _a.text));
            },
        },
        {
            fieldName: "itemAffected",
            title: ["AuditLogs.itemAffected"],
            sortable: data.length > 0,
            width: 350,
            render: (itemAffected, item) => (React.createElement("span", null,
                " ",
                (item.key > 0 && itemAffected)
                    ? React.createElement("div", { className: 'affected_item_wrapper' },
                        React.createElement(TableActions, { actionArray: [
                                {
                                    priority: 1,
                                    title: ["View", "Details"],
                                    onClick: openItemDetailsModal.bind({}, item),
                                    svgIcon: "InfoMini",
                                    isAllowed: true,
                                    btnClass: "affected_item_action_button"
                                }
                            ] }),
                        React.createElement("div", { className: 'audit_more_or_less' }, renderItemAffectedReadMoreLess(itemAffected)))
                    : "")),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        width: 500,
        className: "d-print-none",
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "audit-logs-listing-table", ref: nodeRef },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: "any", scrollPosition: defaultExpandableTableScrollPosition, serverPagination: paginationProps.triggerPagination, scroll: true, serverSorting: true, totalCount: paginationProps.totalCount, currentPage: paginationProps.currentPage, pageSize: paginationProps.pageSize, rowClickHandler: handleRowClick, stateParams: true, paginationSection: paginationSection, searchAllColumn: false })));
};
export default AuditLogsTable;
